'use client';

import Image, { StaticImageData } from 'next/image';

import BannerCardCart from '../../../../../public/assets/banner-cards/banner_cards-cart.webp';
import BannerCardClock from '../../../../../public/assets/banner-cards/banner_cards-clock.webp';
import BannerCardClockBlue from '../../../../../public/assets/banner-cards/banner_cards-clock-blue.webp';
import BannerCardClockYellow from '../../../../../public/assets/banner-cards/banner_cards-clock-yellow.webp';
import BannerCardGear from '../../../../../public/assets/banner-cards/banner_cards-gear.webp';
import BannerCardHand from '../../../../../public/assets/banner-cards/banner_cards-hand.webp';
import BannerCardHandYellow from '../../../../../public/assets/banner-cards/banner_cards-hand-yellow.webp';
import BannerCardHeart from '../../../../../public/assets/banner-cards/banner_cards-heart.webp';
import BannerCardLock from '../../../../../public/assets/banner-cards/banner_cards-lock.webp';
import BannerCardLockGreen from '../../../../../public/assets/banner-cards/banner_cards-lock-green.webp';
import BannerCardMail from '../../../../../public/assets/banner-cards/banner_cards-mail.webp';
import BannerCardTicketSale from '../../../../../public/assets/banner-cards/banner_cards-ticket-sale.webp';
import CategoryDiscount from '../../../../../public/assets/categories/illustration_categories-discount.webp';
import CategoryEggs from '../../../../../public/assets/categories/illustration_categories-eggs.webp';
import CategoryExclusive from '../../../../../public/assets/categories/illustration_categories-exclusive.webp';
import CategoryNew from '../../../../../public/assets/categories/illustration_categories-new.webp';
import CategoryPets from '../../../../../public/assets/categories/illustration_categories-pets.webp';
import CategoryPotions from '../../../../../public/assets/categories/illustration_categories-potions.webp';
import CategoryTransport from '../../../../../public/assets/categories/illustration_categories-transport.webp';
import CategoryUnder from '../../../../../public/assets/categories/illustration_categories-under.webp';
import Baku from '../../../../../public/assets/items/baku.webp';
import Box from '../../../../../public/assets/items/box.webp';
import Buffalo from '../../../../../public/assets/items/buffalo.webp';
import Caravan from '../../../../../public/assets/items/caravan.webp';
import Carpet from '../../../../../public/assets/items/carpet.webp';
import Cow from '../../../../../public/assets/items/cow.webp';
import Feesh from '../../../../../public/assets/items/feesh.webp';
import FlyPotion from '../../../../../public/assets/items/fly-potion.webp';
import JapanEgg from '../../../../../public/assets/items/japan-egg.webp';
import Minion from '../../../../../public/assets/items/minion.webp';
import Neko from '../../../../../public/assets/items/neko.webp';
import NoItem from '../../../../../public/assets/items/no-item.webp';
import RetiredEgg from '../../../../../public/assets/items/retired-egg.webp';
import Rock from '../../../../../public/assets/items/rock.webp';
import Snowball from '../../../../../public/assets/items/snowball.webp';
import WoodlandEgg from '../../../../../public/assets/items/woodland-egg.webp';
import {
  BannerCardItemVariant,
  CategoryItemVariant,
  ItemProps,
} from '../item.types';

export const ItemSize = {
  '218': 218,
  '152': 152,
  '124': 124,
  '96': 96,
  '92': 92,
  '90': 90,
  '68': 68,
  '60': 60,
  '52': 52,
  '48': 48,
  '42': 42,
} as const;

export const ItemImageByVariant = <T,>({
  size,
  variant,
  className,
  style,
}: ItemProps<T>) => {
  const ItemImage: { [key: string]: StaticImageData } = {
    minion: Minion,
    buffalo: Buffalo,
    neko: Neko,
    rock: Rock,
    cow: Cow,
    snowball: Snowball,
    'retired-egg': RetiredEgg,
    'japan-egg': JapanEgg,
    'woodland-egg': WoodlandEgg,
    'fly-potion': FlyPotion,
    baku: Baku,
    carpet: Carpet,
    feesh: Feesh,
    caravan: Caravan,
    box: Box,
    'no-item': NoItem,
  };

  return (
    <Image
      fetchPriority={'high'}
      priority={true}
      style={style}
      loading={'eager'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={ItemImage[variant as keyof typeof ItemImage].src}
      quality={75}
      alt={`${variant} item`}
    />
  );
};

export const CategoryItemImageByVariant = ({
  size,
  variant,
  className,
  srcPrefix = '',
  style,
  srcExtension = 'webp',
  ...props
}: ItemProps<CategoryItemVariant>) => {
  const ItemImage: { [key: string]: StaticImageData } = {
    new: CategoryNew,
    under: CategoryUnder,
    discount: CategoryDiscount,
    pets: CategoryPets,
    eggs: CategoryEggs,
    transport: CategoryTransport,
    exclusive: CategoryExclusive,
    potions: CategoryPotions,
  };

  return (
    <Image
      fetchPriority={'high'}
      priority={true}
      style={style}
      loading={'eager'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={ItemImage[variant as keyof typeof ItemImage].src}
      quality={100}
      alt={`${variant} item`}
    />
  );
};

export const BannerCardItemImageByVariant = ({
  size,
  variant,
  className,
  srcPrefix = '',
  style,
  srcExtension = 'webp',
  ...props
}: ItemProps<BannerCardItemVariant>) => {
  const ItemImage: { [key: string]: StaticImageData } = {
    hand: BannerCardHand,
    'hand-yellow': BannerCardHandYellow,
    lock: BannerCardLock,
    'lock-green': BannerCardLockGreen,
    'clock-yellow': BannerCardClockYellow,
    'clock-blue': BannerCardClockBlue,
    mail: BannerCardMail,
    gear: BannerCardGear,
    clock: BannerCardClock,
    cart: BannerCardCart,
    'ticket-sale': BannerCardTicketSale,
    heart: BannerCardHeart,
  };

  return (
    <Image
      fetchPriority={'high'}
      priority={true}
      style={style}
      loading={'eager'}
      className={className}
      width={ItemSize[size]}
      height={ItemSize[size]}
      src={ItemImage[variant as keyof typeof ItemImage].src}
      quality={90}
      alt={`${variant} item`}
    />
  );
};
