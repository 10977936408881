import _ from 'lodash';
import { useEffect, useState } from 'react';

import { notificationsSocket } from '@/socket/socket';
import {
  authSocketEvents,
  notificationSocketEvents,
  SocketEventsMap,
  statisticsSocketEvents,
} from '@/socket/sockets-events';

export const socketsMap = {
  notifications: notificationsSocket,
  auth: notificationsSocket,
  statistics: notificationsSocket,
} as const;

type A<T> = {
  [K in keyof T]: T[K];
};

export const socketsEvents: A<SocketEventsMap> = {
  notifications: notificationSocketEvents,
  auth: authSocketEvents,
  statistics: statisticsSocketEvents,
};

type WebsocketHookReturn<S extends keyof typeof socketsMap> = {
  isConnected: boolean;
  events: ReturnType<SocketEventsMap[S]>;
  transport: {
    socketName: S;
    transport: string;
  };
};

export const useWebsocket = <S extends keyof typeof socketsMap>({
  socket,
}: {
  socket: S;
}): WebsocketHookReturn<S> => {
  const currSocket = socketsMap[socket];
  const socketEvents = socketsEvents[socket];
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [transport, setTransport] = useState<string>('N/A');

  useEffect(() => {
    if (currSocket.connected) {
      onConnect();
    }

    function onConnect() {
      setIsConnected(true);
      setTransport(currSocket.io.engine.transport.name);

      currSocket.io.engine.on('upgrade', transport => {
        setTransport(transport.name);
      });
    }

    function onDisconnect() {
      setIsConnected(false);
      setTransport('N/A');
    }

    currSocket.on('connect', onConnect);
    currSocket.on('disconnect', onDisconnect);

    return () => {
      currSocket.off('connect', onConnect);
      currSocket.off('disconnect', onDisconnect);
    };
  }, []);

  const events = _.curry(socketEvents);
  const curriedEvents = events({
    socket: currSocket,
  }) as ReturnType<SocketEventsMap[S]>;

  return {
    isConnected,
    events: curriedEvents,
    transport: {
      socketName: socket,
      transport: transport,
    },
  };
};
