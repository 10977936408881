import EggAnimationCrack1 from '@public/assets/lottie/drop-page-egg/crack_1.json';
import EggAnimationCrack2 from '@public/assets/lottie/drop-page-egg/crack_2.json';
import EggAnimationCrack3 from '@public/assets/lottie/drop-page-egg/crack_3.json';
import EggAnimationStars from '@public/assets/lottie/drop-page-egg/stars.json';
import { sleep } from '@utils/delay';
import { sample } from 'lodash';
import { ComponentRef, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useStep } from 'usehooks-ts';

import { mockedData } from '@/mocks/items';
import { GlobalItem } from '@/shared/types/common';
import { ItemVariant } from '@/shared/ui/item/item.types';
export const useEggDropAnimation = () => {
  const lottieRef = useRef<ComponentRef<typeof Lottie>>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isAnimating, setIsAnimating] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [modalOpen, setModalOpen] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ignoreOnComplete, setIgnoreOnComplete] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [dropItem, setDropItem] = useState<GlobalItem | null>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentStep, setCurrentStep] = useState<number>(0);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [currentEggCount, setEggCounter] = useState<number>(10);

  const animationSteps = {
    0: EggAnimationCrack1,
    1: EggAnimationCrack1,
    2: EggAnimationCrack2,
    3: EggAnimationCrack3,
    4: EggAnimationStars,
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
    if (currentStep <= 3) {
      setCurrentStep(currentStep + 1);
      setIsAnimating(true);
    } else {
      setModalOpen(true);
    }
  };

  const handleSmashButtonClick = () => {
    if (currentEggCount > 0) {
      setIgnoreOnComplete(false);
      if (!modalOpen && lottieRef.current) {
        setIsAnimating(true);
        lottieRef.current.play();
      }
    }
  };

  const selectRandomDropItem = <V,>(items: V) => {
    if (Array.isArray(items)) {
      return sample(items);
    } else {
      return null;
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (lottieRef.current && isAnimating) {
      lottieRef.current.play();
    }

    if (currentStep === 3) {
      const item = selectRandomDropItem<GlobalItem[]>(mockedData);

      setDropItem(item);
    }

    if (currentStep === 4) {
      (async () => {
        await sleep(2000).then(() => setModalOpen(true));
        currentEggCount > 0 && setEggCounter(currentEggCount - 1);
      })();
    }
  }, [currentStep, isAnimating]);

  const handleLottieClick = () => {
    if ((currentEggCount === 0 && currentStep !== 0) || currentEggCount > 0) {
      setIgnoreOnComplete(true);
      if (!modalOpen && currentStep <= 3) {
        setIsAnimating(true);
        lottieRef.current?.play();
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handleCloseModal = () => {
    setIsAnimating(false);
    setModalOpen(false);
    setCurrentStep(0);
  };

  return {
    handleCloseModal,
    handleLottieClick,
    handleSmashButtonClick,
    handleAnimationComplete,
    animationSteps,
    currentStep,
    ignoreOnComplete,
    dropItem,
    isAnimating,
    modalOpen,
    lottieRef,
    currentEggCount,
  };
};
