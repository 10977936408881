'use client';
import cn from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React from 'react';

import { LogoProps } from '@/shared/ui/logo/logo.types';

import styles from './logo.module.scss';

export const Logo = ({ className }: LogoProps) => {
  const router = useRouter();

  const loader = ({ src }: { src: string }) => {
    return `/assets/logo/${src}`;
  };

  const containerClasses = cn(styles.container, className);

  return (
    <div className={styles.wrapper}>
      <div onClick={() => router.push('/')} className={containerClasses}>
        <Image
          priority={true}
          loader={loader}
          src={'logo-with-name.svg'}
          fill
          quality={100}
          loading="eager"
          alt={'logo'}
        />
      </div>
    </div>
  );
};
